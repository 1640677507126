<template>
  <div class="contact">
    <div class="contact-info">
      <div class="contact-info__content">
        <div class="contact-info__title">
          <strong>{{ $t('title') }}</strong>
        </div>
      </div>
      <div :class="{ visible: list.length }" class="contact-info__create">
        <plain-button v-if="toolNew" color="primary" @click="add">
          {{ $t('newServer') }}
        </plain-button>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <base-loader v-if="isLoading" />
      <!--      <base-empty v-else-if="!isLoading && !list.length" :title="$t('empty.title')" narrow>-->
      <!--        {{ $t('empty.text') }}-->
      <!--        <base-button v-if="toolNew" @click="add">-->
      <!--          {{ $t('empty.add') }}-->
      <!--        </base-button>-->
      <!--      </base-empty>-->
      <base-table v-else :list="list" :columns="tableHead">
        <contact-table-row v-for="item in list" :key="+item.id" :columns="tableHead" :item="item" />
      </base-table>
    </transition>
  </div>
</template>

<script>
// import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import contextMenuHandler from '@/mixins/contextMenuHandler';
import BaseTable from '@/components/Table/BaseTable.vue';
import ContactTableRow from '@/layouts/Domains/pages/Main/components/ContactTableRow';
import storeMixin from '../../../mixins/index';
import stepper from '@/mixins/billmgr/wizardPay';
import { IspTool } from '@/models/base/IspTools';
import { BillMgrTool } from '@/models/BillMgr/Tools';
import { DnsMgrTool } from '@/models/DnsMgr/DnsMgrTools';
export default {
  name: 'DomainsContact',
  components: {
    // BaseEmpty,
    BaseTable,
    ContactTableRow,
  },
  mixins: [storeMixin, stepper, contextMenuHandler],
  data() {
    return {
      moduleMain: 'moduleDomains.moduleDomainsContact',
      tableHead: [
        {
          key: 'fullname',
          label: this.$t('tableHead.fullname'),
        },
        {
          key: 'domains',
          label: this.$t('tableHead.domains'),
        },
        {
          key: 'more',
        },
      ],
      new: {
        disabled: false,
        key: 'edit',
        tool: {
          func: 'service_profile.edit',
          isValid: true,
          name: 'edit',
        },
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.moduleDomains.moduleDomainsContact.isLoading;
    },
    toolNew() {
      if (!this.tools || !this.tools.new) return null;
      return this.tools.new;
    },
    tools() {
      return this.$store.state.moduleDomains.moduleDomainsContact.tools;
    },
  },
  watch: {
    toolNew(val, old) {
      this.startFunc = val.func;
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      return this.$store.dispatch('moduleDomains/moduleDomainsContact/fetchList');
    },
    newContact(item) {
      return new Promise(async resolve => {
        let needSending = false;
        const payload = item.id ? { elid: item.id } : {};
        let params = {
          func: 'service_profile.edit',
          ...payload,
        };
        await this.$store
          .dispatch('fetchBillMgrToolAction', params)
          .then(async data => {
            // console.log('data_', data);
            const { fields, hidefields, model, slist, pages } = data;
            const config = new this.configuratorClass({
              customfields: fields,
              hidefields: hidefields || [],
              model: model || {},
              slist: slist || {},
              pages: pages || [],
            });
            const res = await this.showEditModal(config);
            // console.log('res', res);
            if (res === 'cancel') resolve('cancel');
            else if (res === 'fail') resolve('fail');
            else {
              params = { ...params, ...res };
              needSending = true;
              // console.log('params', params);
            }
          })
          // .catch(() => resolve('fail'));
          .catch(e => this.showError(e));
        if (needSending) {
          this.$store
            .dispatch('sendBillMgrToolAction', params)
            .then(data => {
              // console.log('---data---', data);
              if (data.ok) {
                this.$store.dispatch(`${this.moduleMainPath}/updateList`);
                resolve('success');
              } else {
                // console.log('data', data);
                resolve('fail');
              }
            })
            .catch(e => {
              this.showError(e);
              // console.log('catch');
              // resolve('fail');
            });
        }
      });
    },
    async runTool(item) {
      if (item.disabled) return;
      const handler = this.newContact(this.newContact);
      this.showLoadingModal();
      const res = await handler;
      // console.log('res', res);
      if (res === 'cancel' && this.modal) this.$modals.close();
      else this.showResModal(res);
    },
    // },
    add() {
      this.runTool(this.newContact);
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "title": "Контакты доменов",
      "newServer": "Новый контакт+",
      "createServer": "Добавить контакт",
      "tableHead": {
        "fullname": "Контакт",
        "domains": "Домен"
      },
      "modal": {
        "res": {
          "success": "Вы успешно добавили контакт",
          "fail": "Произошла ошибка."
        }

      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.contact {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }
    &__title {
      margin-bottom: 0.75em;

    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
</style>
